import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import {

  Heading,
  Icon,
  Loader,
  Button,
  Money,
  MoneyInput,
  P,
  PayPad,
  PaymentForm,
  GroupProgressBar,
  Section,
  Sections,
  SignInLink,
  Subhead
} from 'components'
import { DEFAULT_CURRENCY } from 'config'
import { Money as MoneyHelper } from 'data'
import { postData } from 'helpers/post-data'
import { useApiQuery, useAppWideState, useFormField, useRedirect } from 'hooks'
import { NavigationTarget } from 'data'

interface Props {
  groupId: GroupId
  onSubmit: (data: PaymentFormResult) => Promise<boolean>
}

const DonateAmount: React.FC<Props> = ({ groupId, onSubmit })  => {
  const { isSignedIn } = useAppWideState()
  const [queryString] = useSearchParams()
  const redirect = useRedirect()

  const group = useApiQuery('/api/group/entity', { groupId, includeAverage: true })
  const amount = useFormField(MoneyHelper.amountWithCurrency(15_00, group?.defaultCurrency ?? DEFAULT_CURRENCY))
  const isDonatePay = queryString.get('donate-pay') !== null

  if (!group) {
    return <Loader />
  }

  const sendToPay = () => {
    const path = window.location.pathname;
    const query = window.location.search;
    const url = path + query.replace('?donate-amount', '?donate-pay');
    redirect(new NavigationTarget({ url }))
  }

  return (
    <>
      <Section
        headerLeading={<Subhead>Donate monthly to...</Subhead>}
        headerTrailing={!isSignedIn && <SignInLink />}
      >
        <Heading>{group.name ? group.name : group.recipientFullName}</Heading>
        <hr/>

        <GroupProgressBar groupId={groupId} />

        {!isDonatePay && <>
          <PayPad binding={amount} />
          <MoneyInput binding={amount} min={group.donationMin} max={group.donationMax} required disableFractionalAmounts />
          <Button
            text={<>Donate <Money amount={amount.value} roundIfPossible />/month</>}
            type="submit"
            extraClass="mg-top--lg"
            onClick={sendToPay}
          />
        </>
        }

      {isDonatePay &&
        <PaymentForm
          initialAmount={amount.value}
          buttonText={`Donate Now`}
          collectPayerEmail={!isSignedIn}
          collectPayerName={!isSignedIn}
          collectPayerPhone={!isSignedIn} // TODO: once we have downstream form, just remove this
          currency={group.defaultCurrency}
          maxAmount={group.donationMax}
          minAmount={group.donationMin}
          onSubmit={onSubmit}
          title="Give & Join Group"
        />
      }
      </Section>
    </>
  )
}

export default DonateAmount
