import React from 'react'

import './index.css'

import { AnchorButton, ContactLink, Link, List, Subhead } from 'components'
import { useAppWideState, useFeatureToggle, useApiQuery } from 'hooks'
import { builder } from 'routes'

import CreateGroupBanner from './CreateGroupBanner'
import GroupSpecificLinks from './GroupSpecificLinks'
import SignedInLinks from './SignedInLinks'

interface Props {
  groupId: GroupId | undefined
}
const Navigation: React.FC<Props> = ({ groupId }) => {
  const { isSignedIn, signOut } = useAppWideState()
  const signInOrOut = isSignedIn
    ? <AnchorButton intent="warning" onClick={signOut}>Sign Out</AnchorButton>
    : <Link to={builder.site.signIn}>Sign In</Link>

  const showCreateGroups = useFeatureToggle('showCreateGroupLinks')
  const groupIds = useApiQuery('/api/group/mine', undefined)
  const groupCount = groupIds ? `(${groupIds.length})` : null

  return (
    <nav id="Navigation">
      <List>
        {isSignedIn && 
          <List.Item>
            <Link to={builder.group.all} classes="font-w-500">My Groups {groupCount}</Link>
          </List.Item>
        }

        <List.Item>
          <Link to={builder.user.settings} classes="font-w-500">User Settings</Link>
        </List.Item>  

        {(groupId && !!groupIds && groupIds.length === 1) &&
          <List.Item>
            <Link to={builder.group.changeSubscription(groupId)}>Edit Payment</Link>
          </List.Item>
        } 
        
        {isSignedIn && showCreateGroups && <CreateGroupBanner /> }
        <List.Item>
          <Link to={builder.site.faq}>FAQs</Link>
        </List.Item>

        <List.Item><ContactLink>Contact Us</ContactLink></List.Item>
        <List.Item>{signInOrOut}</List.Item>
      </List>
    </nav>
  )
}

export default Navigation
