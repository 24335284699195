import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  AnchorButton,
  CardInfo,
  Heading,
  Money,
  Name,
  P,
  Time,
} from 'components'
import { useApiQuery } from 'hooks'

import Cancel from './Cancel'
import ChangeAmount from './ChangeAmount'
import ChangePM from './ChangePM'

enum Mode {
  Change,
  Cancel
}

interface Props {
  groupId: GroupId
  subscriptionId: SubscriptionId
}
const ManageActive: React.FC<Props> = ({ groupId, subscriptionId }) => {
  const navigate = useNavigate()
  const [mode, setMode] = useState(Mode.Change)
  const group = useApiQuery('/api/group/entity', { groupId })
  const permissions = useApiQuery('/api/group/me', { groupId })
  const sub = useApiQuery('/api/subscription/entity', { subscriptionId })
  if (!permissions || !group || !sub) {
    return null
  }


  // Notes:
  //
  // The design calls for "billed on the XXth of each month". This is annoying
  // both due to data we store and end-of-month edge cases. It'll also be super
  // broken if we offer any non-monthly interval.
  //
  // The design uses "payment" but our terminology is such that "payment" is
  // the individual instance. I'm leaving it as-is for typographical reasons
  // (our huge font renders "Change\nSubscription" which is ugly). This impacts
  // the nav menu too.
  //
  // Change {amount, payment method} functionality doesn't exist yet. The
  // former is super annoying on the backend. Leaving both out for now.

  // TODO: change amount
  // TODO: change payment method
  let actions: React.ReactNode

  if (mode === Mode.Cancel) {
    actions = <Cancel
      doNotCancel={() => setMode(Mode.Change)}
      group={group}
      groupId={groupId}
      isOrganizer={permissions.roles.isOrganizer}
      subscriptionId={sub.id}
    />
  } else if (mode === Mode.Change) {
    if (sub.isActive) {
      actions = <>
        <P centered>
          <AnchorButton intent="warning" onClick={() => setMode(Mode.Cancel)}>Cancel Payment</AnchorButton>
        </P>
        <P centered>
          <AnchorButton onClick={() => navigate(-1)} color="#979797">Go back</AnchorButton>
        </P>
      </>
    } else {
      // FIXME: "as of xx/xx/xx" or similar ~ need backend support
      actions = <P><strong>This payment has been canceled.</strong></P>
    }
  }

  return (<>
    <Heading>{mode === Mode.Change ? 'Change' : 'Cancel'} Payment</Heading>

    <P>{sub.paymentMethodId && <CardInfo paymentMethodId={sub.paymentMethodId} />}</P>

    <P>
      <strong>Your current gift amount</strong> is <Money amount={sub.amount} highlighted /> per month to <Name name={group.recipientFullName} role="recipient" />, billed as <Money amount={sub.total} /> including fees.
      {/* isActive is checked due to the two-phase cancelation on the backend. */}
    </P>
    {sub.nextPaymentAt !== null && sub.isActive &&
      <P>Your next payment will be on <Time unixtimeSeconds={sub.nextPaymentAt} />.</P>
    }

    <ChangeAmount currentAmount={sub.amount} groupId={groupId} subscriptionId={sub.id} />
    <ChangePM amount={sub.amount} groupId={groupId} subscriptionId={sub.id} />

    {actions}

  </>)
}

export default ManageActive
