import React, { useEffect, useState } from 'react'

import {
  Anchor,
  AnchorButton,
  Button,
  Heading,
  Icon,
  Link,
  Loader,
  P,
} from 'components'
import { NavigationTarget } from 'data'
import { getFirstName } from 'helpers';
import { postData } from 'helpers/post-data'
import { useApiQuery, useFeatureToggle, useRedirect } from 'hooks'
import { builder, groupIdInUrl, mustBeSignedIn } from 'routes'

import BaseLayout from 'blocks/layout/base-layout';


import './index.css'

const initialClipboardText = 'Copy Link to Clipboard'
// FIXME: member limit should be group API result

interface Props {
  groupId: GroupId
}

const trackEvent = (n: string, gid: string) => {
    postData('/api/analytics/track', { funnel: "GROUP_CREATE", event: n, url: window.location.href, groupId: gid })
    return false
}

const InviteContacts: React.FC<Props> = ({ groupId }) => {
  const group = useApiQuery('/api/group/entity', { groupId })
  const permissions = useApiQuery('/api/group/me', { groupId })
  const [clipboardText, setClipboardText] = useState(initialClipboardText)
  const redirect = useRedirect()
  const trackSMS = (e: React.MouseEvent<HTMLAnchorElement>) => {trackEvent('APP_GC_INVITE_TEXT', groupId)}
  const trackEmail = (e: React.MouseEvent<HTMLAnchorElement>) => {trackEvent('APP_GC_INVITE_EMAIL', groupId)}
  const trackLink = () => {trackEvent('APP_GC_INVITE_LINK', groupId)}
  useEffect(() => {
    postData('/api/analytics/track', { funnel: "GROUP_CREATE", event: 'APP_GC_INVITE_CONTACTS', url: window.location.href, groupId })
  }, [groupId])
  const showPreviewLink = useFeatureToggle('shareSheetPreview')

  if (!group || !permissions) {
    return <Loader />
  }

  if (!permissions.permissions.inviteMembers) {
    redirect(new NavigationTarget({ url: builder.group.view(groupId)}))
    return null
  }

  const memberLandingPage = builder.share.memberLanding(groupId)
  const recipientFirstName = getFirstName(group.recipientFullName)

  const smsUrl = 'sms:?'
    + '&body=' + encodeURIComponent(
      `${memberLandingPage}`+"?utm_source=autogive&utm_medium=sms&utm_content=invitecontacts"
      + `\n\nHi - I'm inviting you to support ${group.recipientFullName} using AutoGive - the invite-only way to collect monthly donations. Please tap the link above to join my group now. Thanks!`
    )

  // See #531 for restoring google support on how it was removed
  const emailUrl = 'mailto:'
    + '?subject=' + encodeURIComponent(`Help support ${group.recipientFullName}`)
    + '&body=' + encodeURIComponent(
        `Hello,`
      + `\n\nI'm inviting you to support ${group.recipientFullName} using AutoGive - the invite-only way to collect monthly donations.`
      + `\n\nDonate now and help support ${recipientFirstName}:`
      + `\n\n${memberLandingPage}`+"?utm_source=autogive&utm_medium=email&utm_content=invitecontacts"
      + `\n\nThank you!`
    )

  const copyToClipboard = (e: React.MouseEvent) => {
    e.preventDefault()
    trackLink()
    navigator.clipboard.writeText(memberLandingPage+"?utm_source=autogive&utm_medium=coyplink&utm_content=invitecontacts")
    setClipboardText('Copied!')
    setTimeout(() => {
      setClipboardText(initialClipboardText)
    }, 1000);
  }

  const doneUrl = builder.group.view(groupId)

  return (<BaseLayout>
    <div className="inviteContacts">
      <Heading>Invite People to Donate</Heading>

      <P><strong>Did you know?</strong> Inviting friends & family is the best way to ask for help and meet your monthly goal.</P>

      {/*<P><Icon icon="lock" size="small" inline />For extra privacy, you’ll be asked to approve anyone joining your group.</P>*/}
      {showPreviewLink && <P>
        <strong>My Group Page: </strong>
        <a href={builder.group.memberLandingPreview(groupId)} className="Link font-semibold" target="_blank">Preview</a> or&nbsp;
        <Link to={builder.group.editGroupDetails(groupId)} classes="font-semibold">Edit</Link>        
      </P>}
      <br/>
      {/*Maybe hide this?*/}
      <div className="share-link__wrap">{memberLandingPage}</div>

      <a href={smsUrl} className="Button" onClick={copyToClipboard}>{clipboardText}</a>
      <a href={smsUrl} className="Button" onClick={trackSMS}>Text Friends & Family</a>
      <a href={emailUrl} className="Button" onClick={trackEmail}>Email Your Contacts</a>      

{/*
      <P><IconButton url={smsUrl} onClick={trackSMS} icon="phone">Send a Text Message</IconButton></P>
      <P><IconButton icon="clipboard" onClick={copyToClipboard}>{clipboardText}</IconButton></P>*/}
      {/*<P classes="mg-top--gap-3xl">Or, use email to invite contacts to your group.</P>*/}   

      {/*<P centered={true}>Up to <strong>100</strong> people may join your group.</P>*/}

      <P classes="font-semibold mg-top--gap-2xl" centered><Link to={doneUrl}>I'm done inviting others</Link></P>      
    </div>
  </BaseLayout>)
}

interface IBProps {
  icon: IconName
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  url?: string
  children: Children
}
const IconButton: React.FC<IBProps> = ({ icon, children, onClick, url }) => {
  const inner = <span className="IconButton">
    <Icon icon={icon} />
    <span className="IconButtonText">{children}</span>
  </span>
  if (onClick) {
      return <Anchor url={url!} onClick={onClick!}>{inner}</Anchor>
  } else {
      return <Anchor url={url!}>{inner}</Anchor>
  }
}
export default mustBeSignedIn(groupIdInUrl(InviteContacts))
