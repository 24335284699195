import React from 'react'
import classNames from 'classnames'

import './index.scss';
import { FormRow } from 'components'

interface Props {
    disabled?: boolean
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    text: React.ReactNode
    type: 'submit' | 'reset' | 'button'
    extraClass?: string
    loading?: boolean
}
const Button: React.FC<Props> = ({
    disabled = false,
    onClick = undefined,
    text,
    type = 'submit',
    extraClass,
    loading
}) => {
    const loadIcon = `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="6" stroke="#eee" stroke-width="2" fill="none"></circle>
      <circle cx="14" cy="8" fill="#7D92E0" r="1">
        <animateTransform attributeName="transform" attributeType="XML" type="rotate" from="0 8 8" to="360 8 8" dur="750ms" repeatCount="indefinite"></animateTransform>
      </circle>
    </svg>`
   return (
        <FormRow>
            {/*<button className={`${extraClass ? extraClass : 'Button'}`} type={type} disabled={disabled} onClick={onClick}>*/}
            <button className={classNames('Button', extraClass)} type={type} disabled={disabled} onClick={onClick}>
                {loading ? <span dangerouslySetInnerHTML={{ __html: loadIcon }} /> : text}
            </button>
        </FormRow>
    )
}

export default Button;

